<template>
  <div></div>
</template>
<script>
export default {
  name: 'AuthEkyzmat',
  created() {
    if(Object.prototype.hasOwnProperty.call(this.$route.query, 'code')
        && Object.prototype.hasOwnProperty.call(this.$route.query, 'state')) {
      this.$axios.get('/auth-ekyzmat/login', {
        params: {
          code: this.$route.query.code,
          state: this.$route.query.state,
          sender: process.env.NODE_ENV === 'development' ? 'local' : 'production'
        }
      }).then(({data}) => {
        localStorage.setItem('token', data.token)
        this.$store.commit('setToken', {
          token: data.token
        })
        this.$store.dispatch('fetchUser').then(() => {
          if(this.$store.getters.isRaiono || this.$store.getters.isGorono) {
            this.$router.push('/admin/quotas?type=SCHOOL')
          } else if(this.$store.getters.isEmployee) {
            this.$router.push('/admin/applications?status=ENROLLED')
          } else {
            this.$router.push('/admin')
          }
        })
      })
    }
  }
}
</script>